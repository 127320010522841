<template lang="pug">
.flex.flex-col.overflow-y-auto.w-full
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.service.maintenance") }}
  .flex.flex-col
    .flex.my-3.relative
      .flex.flex-col
        p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.service.for") }}
        .flex.mt-2.items-center.mr-4
          skif-input-number(
            :disabled='isAllowedCreate && role !== "EDITOR"',
            v-model.number='notification.interval_days',
            :error='validate'
          )
          p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{ $t("notifications.service.days") }}
      .flex.flex-col
        p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.service.byMilage") }}
        .flex.mt-2.items-center
          skif-input-number(
            :disabled='isAllowedCreate && role !== "EDITOR"',
            v-model.number='notification.interval_milage',
            :error='validate'
          )
          p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{ $t("notifications.service.km") }}
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold  {{ $t("notifications.motohours") }}
      skif-input-number.w-40.mt-2(
        type='number',
        :disabled='isAllowedCreate && role !== "EDITOR"',
        v-model.number='notification.interval_motohours',
        :error='validate'
      )
      transition(name='el-zoom-in-top')
        .mt-2.text-notify.text-xs(v-if='validate') {{ $t("notifications.service.warning") }}

  .flex
    .flex.flex-col.mt-6
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.sensor.maskByName") }}
      el-input.w-40.mt-2(
        :disabled='isAllowedCreate && role !== "EDITOR"',
        v-model='notification.name_mask'
      )
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isIntervalDaysOrMilage'],
  computed: {
    validate: {
      get() {
        console.log('t', this.isIntervalDaysOrMilage)
        return this.isIntervalDaysOrMilage
      },
      set(newValue) {
        this.$emit('updateIntervalDaysOrMilage', newValue)
        return newValue
      }
    },
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    }
  },
  watch: {
    'notification.interval_days': function (val) {
      this.validate =
        val === '' &&
        this.notification.interval_milage === '' &&
        this.notification.interval_motohours === ''
    },
    'notification.interval_milage': function (val) {
      this.validate =
        val === '' &&
        this.notification.interval_days === '' &&
        this.notification.interval_motohours === ''
    },
    'notification.interval_motohours': function (val) {
      this.validate =
        val === '' &&
        this.notification.interval_days === '' &&
        this.notification.interval_milage === ''
    }
  }
}
</script>
